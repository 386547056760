import React from "react"
import Header from "./header"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath || !isNaN(location.pathname.slice(1))

  return (
    <div className="global-wrapper">      
      <Header title={title} isRootPath={isRootPath} />
      <main>{children}</main>
      <footer className="colophon">        
      {new Date().getFullYear()} <img src="https://2.bp.blogspot.com/-EGK24I6UISU/ToqbXls3xRI/AAAAAAAAAqQ/0YEGaFBqfBQ/s164/valvestylefs.png" alt="fansite" style={{verticalAlign:'middle'}} /> dota2VN.com<br />
        Dota 2 is a registered trademark of Valve Corporation.
      </footer>
    </div>
  )
}

export default Layout
