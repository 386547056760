import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { FacebookProvider, Like } from 'react-facebook';

const Header = ({ title, isRootPath }) => {
  let header
  const data = useStaticQuery(graphql`
  query {
    logo: file(absolutePath: {regex: "/dedgaem.png/"}) {
      childImageSharp {
        fixed(quality: 95) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)
  const logo = data?.logo?.childImageSharp?.fixed
  if (isRootPath) {
    header = (
    <>
      <h1 className="main-heading logo">
        <Link to="/">
          <Image
            fixed={logo}
            alt="logo"
          />
        </Link>
      </h1>
      <nav className="nav-menu">
        <ul>          
          <li><Link to="/faq">FAQ</Link></li>
          <li><Link to="/glossary">Dict</Link></li>
          <li><Link to="/ranks">Ranks</Link></li>
        </ul>
        <div className="fb-like" data-href="https://www.facebook.com/dota2vietnam" data-layout="button" data-action="like" data-size="large" data-share="false"></div>
        <FacebookProvider appId={process.env.GATSBY_FACEBOOK_APP_ID}>
          <Like href="https://www.facebook.com/dota2vietnam" colorScheme="dark" layout="button_count" size="large" />
        </FacebookProvider>
      </nav>
    </>
    )
  } else {
    header = (
    <>
      <Link className="header-link-home" to="/">
        ← {title}
      </Link>
      <FacebookProvider appId={process.env.GATSBY_FACEBOOK_APP_ID}>
        <Like href="https://www.facebook.com/dota2vietnam" colorScheme="dark" layout="button_count" size="large" />
      </FacebookProvider>
    </>
    )
  }
  return (
  <header className="global-header" data-is-root-path={isRootPath}>{header}</header>
  )
}

export default Header